<template>
  <div id="SuccessfulPay">
    <main>
      <div class="box">
        <div class="main-box">
          <div class="buy">
            <p>
              <i class="el-icon-success"></i>
              购买成功
            </p>
          </div>
          <p>
            订单编号：
            <span>{{ invoiceCode }}</span>
          </p>
          <p>
            微信支付：
            <span class="text">
              <span>{{ price }}</span>
              <span>元</span>
            </span>
          </p>
          <div class="ToPayAfter" @click="ToPayAfter">查看订单详情</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import API from '@/api'
export default {
  name: 'SuccessfulPay',
  data() {
    return {
      data: '',
      CourseId: {
        id: '',
      },
      invoiceCode: '',
      price: '',
      PayState: {
        id: '',
      }, // 支付状态
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.CourseId.id = this.$route.query.id;
      this.invoiceCode = this.$route.query.invoiceCode;
      this.price = this.$route.query.price;
      this.PayState.id = this.$route.query.orderId;
    },
    ToPayAfter() {
      this.$router.push({
        path: '/PayAfter',
        query: {
          courseId: this.CourseId.id,
          orderId: this.PayState.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 1200px;
  height: 80vh;
  margin: auto;
  .box {
    width: 600px;
    height: 80vh;
    margin: auto;
    text-align: center;
    position: relative;
    .main-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 600px;
      height: 40vh;
      p {
        margin: 30px;
      }
      .text {
        color: #f7b515;
        font-size: 30px;
      }
      .ToPayAfter {
        color: #f7b515;
        cursor: pointer;
      }
      .buy {
        img {
          width: 30px;
          height: 30px;
          display: inline;
        }
        color: #47b411;
        font-size: 24px;
      }
    }
  }
}
</style>
